import _ from 'lodash';

import {
  baseConfig,
  localConfig,
  mockConfig,
  prodNEConfig,
  rc0Config,
  rc1Config,
  rc2Config,
  rcStagingConfig,
  releaseStagingConfig,
  testConfig,
  trunkConfig,
  trunkProxyConfig,
} from './environments';
import playground1Config from './environments/playground1.env';
import prodWeConfig from './environments/prodWE.env';

const reactEnvRegex = /^REACT_APP_/;

const fetchConfigFromSystemVars = () =>
  _.chain({ ...process.env })
    .pickBy((_value, key) => reactEnvRegex.test(key))
    .mapKeys((_value, key) => _.camelCase(key.replace(reactEnvRegex, '')))
    .value();

export const getEnvContext = () => {
  switch (process.env.REACT_APP_TV_ENV?.toLowerCase()) {
    case 'local':
      return localConfig;
    case 'mock':
      return mockConfig;
    case 'test':
      return testConfig;
    case 'trunk':
      return trunkConfig;
    case 'trunkproxy':
      return trunkProxyConfig;
    case 'rc0':
      return rc0Config;
    case 'rc1':
      return rc1Config;
    case 'rc2':
      return rc2Config;
    case 'release':
      return releaseStagingConfig;
    case 'rc':
      return rcStagingConfig;
    case 'playground1':
      return playground1Config;
    case 'productionne':
      return prodNEConfig;
    case 'productionwe':
      return prodWeConfig;
    default:
      return baseConfig;
  }
};

export const isProd = () => {
  switch (process.env.REACT_APP_TV_ENV?.toLowerCase()) {
    case 'rc0':
    case 'rc1':
    case 'rc2':
    case 'rc':
    case 'productionne':
    case 'productionwe':
      return true;
    default:
      return false;
  }
};

/**
 * Retrieves the desired configuration depending on the context environment.
 * The order of which a configuration is fetched:
 * 1. System variables (.env) config
 * 2. environment config (dev or prod)
 * 3. base config
 * @param key key of wanted config value
 */
const configManager = {
  get: <K extends ConfigKeys>(key: K): AppConfig[K] => {
    // extract system varaibles
    const systemConfig = fetchConfigFromSystemVars();
    const envConfig = getEnvContext();
    // merged configs
    const config = _.defaults(systemConfig, envConfig, baseConfig);

    return config[key];
  },
};

export default configManager;
