import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CheckboxFormInput, Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { Banner, InfoContainer, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import { IconSize } from 'components/InfoContainer/InfoContainer.model';
import { configManager } from 'config';
import { useAppDispatch, useAppSelector, useNavigateWithRedirect, useResponsive } from 'hooks';
import { ScreenSize } from 'hooks/useResponsive';
import { LoginStatus } from 'models';
import { authActions } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';
import { getCheckBoxRequiredRules } from 'utils/validationRules';

import { useStyles } from './UntrustedDevice.styles';

const UntrustedDevice = () => {
  const { t } = useTranslation(['unverifiedDevice', 'banner']);
  const navigate = useNavigateWithRedirect();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { screenSize } = useResponsive();
  const { loginStatus } = useAppSelector((state) => state.auth);
  const {
    control,
    formState: { isValid },
  } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');

  const { buttonStyles, checkboxStyles, descriptionTextStyles, emailTextStyles, subTextStyles, titleStyles } =
    useStyles();

  useEffect(() => {
    if (loginStatus !== LoginStatus.TrustDevicePending) {
      navigate(
        {
          pathname: '/',
          search: new URLSearchParams({
            redirect_uri: redirectUri,
          }).toString(),
        },
        { replace: true },
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const { linkStyles } = useLinkStyles();

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <Stack>
            <TextPrimary data-testid="new-device-title" className={titleStyles}>
              {t('header')}
            </TextPrimary>
          </Stack>
          <Stack>
            <InfoContainer
              icon="/assets/email_inbox.svg"
              iconAccessibility={t('emailSent')}
              iconSize={IconSize.big}
              text={
                <TextSecondary variant="medium" className={descriptionTextStyles}>
                  {splitAndStyleString(
                    t('subheader', { email: `<b>${searchParams.get('email')}</b>` }),
                    /<b>|<\/b>/,
                    emailTextStyles,
                    undefined,
                    1,
                    '_self',
                    'new-device-email',
                  )}
                </TextSecondary>
              }
              vertical={screenSize <= ScreenSize.Laptop}
            />
          </Stack>
          <Stack>
            <CheckboxFormInput
              className={checkboxStyles}
              rules={getCheckBoxRequiredRules()}
              name="hasVerifiedDevice"
              control={control}
              label={t('hasVerifiedDeviceCheckbox')}
              ariaLabel={t('hasVerifiedDeviceCheckbox')}
              tabIndex={3}
              data-testid="new-device-already-added-checkbox"
            />
          </Stack>
          <SubmitButton
            className={buttonStyles}
            isValid={isValid}
            isLoading={false}
            isCheckmarkVisible={false}
            label={t('signIn')}
            onClick={() => {
              dispatch(authActions.setLoginStatus(LoginStatus.ReadyForLogin));
              navigate(`/?email=${searchParams.get('email') || ''}`, { replace: true });
            }}
            tabIndex={4}
            data-testid="new-device-submit-btn"
          />
          <Stack.Item className={subTextStyles}>
            {splitAndStyleString(
              t('checkSpamFolder', {
                trustedDeviceHelpPage: `<a>${t('trustedDeviceHelpPage')}</a>`,
              }),
              /<a>|<\/a>/,
              linkStyles,
              `https://www.teamviewer.com/link/?url=953246`,
            )}
          </Stack.Item>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.Security />
      </ModalBanner>
    </Modal>
  );
};

export default UntrustedDevice;
