import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(9),
        margin: 0,
      },
      formSectionStyles: {
        paddingTop: tokens.spacing(4),
      },
      emailTextStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(5),
        overflow: 'hidden',
      },
    }) as const,
);
