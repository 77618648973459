const rc2Config: Partial<AppConfig> = {
  apiUrl: '/',
  defaultRedirectUri: 'https://rc.web.tvwebservices.org/authenticate/withtoken',
  webClientUri: 'https://rc.web.tvwebservices.org/',
  invisibleRecaptchaSiteKey: '6Lfw5CMhAAAAABQHrMWqCNGxgHkkB-SjlPCTjWyn',
  recaptchaSiteKey: '6Ledv-IUAAAAAHltPRfgliZXuL_tApeW1p31zuWF',
  isTestMaster: false,
  adobeDtmUrl: 'https://assets.adobedtm.com/439d99b3b8f0/eec744300fc5/launch-2ca96c33f9ad-staging.min.js',
  masterPublicKey: {
    kty: 'RSA',
    e: 'AQAB',
    n: 'wnytInIjAj-SKVZj3xy2tHdnkJ0upwNDPiTTqu1sLQXlBUtxdxEwFgXE7YrINSuut-BXXu9AdCND_mVjekWaycD3V5UYZHxMWvshD9gsOJBHw28oN5Rmsr0mbi8d_DbYdH1pweg7BYnCQDqJ9GqhXaW_IuUE16he8mJnbkNPJGc',
  },
};

export default rc2Config;
