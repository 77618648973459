import { useEffect, useState } from 'react';

import { ClientOs, ClientType } from 'models';
import useAppSelector from './useAppSelector';

export const shouldBeVisibleFor = (clientType: ClientType, clientOs?: ClientOs) => {
  switch (clientOs) {
    case ClientOs.iOS:
    case ClientOs.iPadOS:
    case ClientOs.Android:
      return false;
    default:
      return clientType === ClientType.NativeClient;
  }
};

export const shouldDisableZoomFor = (clientType: ClientType, clientOs?: ClientOs) => {
  if (clientType !== ClientType.NativeClient) {
    return false; // Do not disable zoom for non-native clients
  }

  switch (clientOs) {
    case ClientOs.iOS:
    case ClientOs.iPadOS:
    case ClientOs.Android:
      return true; // Disable zoom for iOS and iPadOS
    default:
      return false; // Do not disable zoom for other OSes
  }
};

// The purpose of this hook is to determine the visibility of the back button based on the client type and operating system.
// For native clients (specifically on iOS, iPadOS, and Android), the back button should not be visible,
// while for web clients, it should be. This is essential for ensuring a consistent user experience across different platforms.
export const useIsBackButtonVisible = () => {
  const [isVisible, setIsVisible] = useState(true);

  const {
    config: { clientType, clientOs },
  } = useAppSelector((state) => state.serviceConfig);

  useEffect(() => {
    setIsVisible(shouldBeVisibleFor(clientType, clientOs));
  }, [clientType, clientOs]);

  return isVisible;
};

// The purpose of this hook is to prevent zoom on Apple mobile devices because
// focusing on input field with too small font size will zoom the view in slightly
// and view overflows the screen size
export const useDisableZoomOnMobileDevices = () => {
  const {
    config: { clientType, clientOs },
  } = useAppSelector((state) => state.serviceConfig);

  useEffect(() => {
    const disableZoom = shouldDisableZoomFor(clientType, clientOs);

    if (disableZoom) {
      // Disable zoom on mobile devices
      const viewport = document.querySelector('meta[name=viewport]');
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
      }
    }
  }, [clientType, clientOs]);
};
