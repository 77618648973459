import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { mergeCss, mergeStyleSets } from '@fluentui/merge-styles';
import { Stack } from '@fluentui/react';
import { useConnectionStatus } from '@teamviewer/utils';
import type { TFunction } from 'i18next';
import Router from 'Router';

import { Footer, Header } from 'components';
import { ThemeProviderFui } from 'components/Theme';
import { useAppDispatch, useAppSelector, useDisableZoomOnMobileDevices, useResponsive } from 'hooks';
import { useCookieBanner } from 'hooks/useCookieBanner';
import { ScreenSize } from 'hooks/useResponsive';
import { ClientOs, ClientType, ServiceConfigStatus } from 'models';
import { Loading } from 'pages';
import ErrorScreen from 'pages/ErrorScreen';
import { loadInitialConfigAction } from 'store';
import { OfflineScreen } from './components/OfflineScreen/OfflineScreen';

import { footerMarginStyles, pageContentStyles, pageContentWrapperStyles, toastContainerStyles } from 'App.styles';
import 'react-toastify/dist/ReactToastify.css';

function App(): JSX.Element {
  const { t } = useTranslation('offline_screen');
  const {
    serviceConfigStatus,
    config: { clientType, clientOs },
  } = useAppSelector((state) => state.serviceConfig);
  const isNativeClient = clientType === ClientType.NativeClient;
  useInitialConfig();
  useCookieBannerConfig(serviceConfigStatus, isNativeClient);
  useDisableZoomOnMobileDevices();

  const isOnline = useConnectionStatus();
  const defaultClientOs = ClientOs.Unknown;
  const unwrappedClientOs = clientOs || defaultClientOs;
  const { screenSize } = useResponsive();

  return (
    <>{renderByServiceConfigStatus(serviceConfigStatus, isNativeClient, screenSize, unwrappedClientOs, isOnline, t)}</>
  );
}

function useInitialConfig(): void {
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(document.location.search);
  useEffect(() => {
    dispatch(
      loadInitialConfigAction({
        isNativeClient: searchParams.get('is_native_client')?.toLowerCase() === 'true',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function useCookieBannerConfig(serviceConfigStatus: ServiceConfigStatus, isNativeClient: boolean): void {
  useCookieBanner({
    hide:
      serviceConfigStatus !== ServiceConfigStatus.Set ||
      isNativeClient ||
      process.env.REACT_APP_HIDE_COOKIE_BANNER === 'true',
  });
}

interface RenderProps {
  t: (key: string) => string;
  isNativeClient: boolean;
}

function renderByServiceConfigStatus(
  serviceConfigStatus: ServiceConfigStatus,
  isNativeClient: boolean,
  screenSize: ScreenSize,
  clientOs: ClientOs,
  isOnline: boolean,
  t: TFunction,
): JSX.Element {
  const showHeader = (!isNativeClient || screenSize <= ScreenSize.Tablet) && isOnline;
  const showFooter = !isNativeClient && isOnline;
  const renderContent = (MainComponent: React.FC<RenderProps>) => (
    <Stack
      verticalAlign="start"
      styles={!isNativeClient ? mergeStyleSets(pageContentStyles, footerMarginStyles) : pageContentStyles}
    >
      {showHeader && <Header />}
      <MainComponent t={t} isNativeClient={isNativeClient} />
      {showFooter && <Footer />}
    </Stack>
  );

  const wrapWithCommonUI = (children: React.ReactNode) => (
    <BrowserRouter>
      <ThemeProviderFui>
        <Stack styles={pageContentWrapperStyles}>
          <ToastContainer
            autoClose={false}
            draggable={false}
            closeOnClick={false}
            closeButton={false}
            limit={4}
            className={mergeCss(toastContainerStyles)}
          />
          {children}
        </Stack>
      </ThemeProviderFui>
    </BrowserRouter>
  );

  if (!isOnline && ![ClientOs.iOS, ClientOs.iPadOS].includes(clientOs)) {
    return wrapWithCommonUI(renderContent(() => <OfflineScreen t={t} isNativeClient={isNativeClient} />));
  }

  switch (serviceConfigStatus) {
    case ServiceConfigStatus.Set:
      return wrapWithCommonUI(renderContent(Router));
    case ServiceConfigStatus.Dirty:
      return (
        <ThemeProviderFui>
          <Stack styles={pageContentWrapperStyles}>
            <ErrorScreen />
          </Stack>
        </ThemeProviderFui>
      );
    default:
      return <Loading />;
  }
}

export default App;
