import { useTranslation } from 'react-i18next';
import { IconButton, Modal } from '@teamviewer/ui-library';

import { useIconButtonStyles } from './CopyrightModal.styles';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const CopyrightModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation('common');
  const classes = useIconButtonStyles();
  return (
    <div>
      <Modal
        titleAriaId="copyright-modal"
        isOpen={isOpen}
        onDismiss={close}
        isBlocking={false}
        containerClassName={classes.container}
      >
        <div className={classes.header} data-testid="footer-copyright-dialog">
          <h1>{t('copyright')}</h1>
          <IconButton
            className={classes.iconStyle}
            size="large"
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={close}
            data-testid="footer-copyright-close-btn"
          />
        </div>
        <div className={classes.body}>
          <h2>{t('copyrightViewHeadline').toLocaleUpperCase()}</h2>
          <pre>{COPYRIGHT_ATTRIBUTION}</pre>
        </div>
      </Modal>
    </div>
  );
};

export default CopyrightModal;
