import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, TextFormInput, TextPrimary, TextSecondary, themeSpacings } from '@teamviewer/ui-library';
import { withValidationAgainstLinks } from '@teamviewer/utils';
import type { Namespace, TFunction } from 'i18next';

import { Banner, Divider, IconLink, Modal, ModalBanner, ModalMain, SocialLoginButton, SubmitButton } from 'components';
import { IButtonType } from 'components/SocialLoginButton/SocialLoginButton';
import { configManager } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ClientType } from 'models';
import { registerAccountActions } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';
import { getEmailValidationRules, getNameValidationRules } from 'utils/validationRules';

import { dividerStyles, useStyles } from '../RegisterAccount.styles';

interface IProps {
  email: string | null;
  name: string | null;
  t: TFunction<Namespace<'en' | 'de'>, undefined>;
  onContinue(email: string, name: string): void;
}

const RegisterAccountNameAndEmail = ({ name, email, t, onContinue }: IProps) => {
  const featureFlags = configManager.get('featureFlags');
  const dispatch = useAppDispatch();
  const { clientType } = useAppSelector((state) => state.serviceConfig.config);
  const isNativeClient = clientType === ClientType.NativeClient;

  const { linkStyles } = useLinkStyles();

  useEffect(() => {
    dispatch(registerAccountActions.clear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<{
    name: string;
    email: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { email: email ?? '', name: name ?? '' },
  });

  const onSubmit = handleSubmit(({ email, name }) => onContinue(email, name));

  const nameValudationRules = useMemo(
    () => withValidationAgainstLinks(getNameValidationRules, 'enterValidName')(t),
    [t],
  );

  const { alreadyHaveAnAccountStyles, formSectionStyles, subtitleStyles, titleStyles } = useStyles();

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <Stack.Item>
            <Stack as="header">
              {isNativeClient ? (
                <IconLink iconName="Back" as="LinkWithRedirect" link="/" tabIndex={1} data-testid="signin-go-back-link">
                  <TextPrimary as="h1" block data-testid="registration-email-title" className={titleStyles}>
                    {t('title')}
                  </TextPrimary>
                </IconLink>
              ) : (
                <TextPrimary as="h1" block data-testid="registration-email-title" className={titleStyles}>
                  {t('title')}
                </TextPrimary>
              )}

              <TextSecondary
                variant="medium"
                className={subtitleStyles}
                nowrap
                block
                data-testid="registration-email-subtitle"
              >
                {t('subtitle')}
              </TextSecondary>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <form onSubmit={onSubmit} noValidate>
              <Stack className={formSectionStyles} data-testid="registration-email-form">
                <Stack tokens={{ childrenGap: themeSpacings.l }}>
                  <Stack.Item>
                    <Stack tokens={{ childrenGap: themeSpacings.s }}>
                      <Stack.Item>
                        <TextFormInput
                          control={control}
                          inputMode="text"
                          name="name"
                          rules={nameValudationRules}
                          autoComplete="name"
                          label={t('nameInput')}
                          ariaLabel={t('nameInput')}
                          data-testid="registration-name-input"
                          autoFocus
                          tabIndex={1}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <TextFormInput
                          control={control}
                          inputMode="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          rules={getEmailValidationRules(t)}
                          label={t('email')}
                          ariaLabel={t('email')}
                          data-testid="registration-email-input"
                          tabIndex={2}
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <SubmitButton
                      data-testid="registration-email-submit-btn"
                      isValid={isValid}
                      isLoading={false}
                      label={t('continue')}
                      isCheckmarkVisible={false}
                      tabIndex={3}
                    />
                  </Stack.Item>
                </Stack>
                {featureFlags.socialLogin && (
                  <Stack>
                    <Divider text={t('divider')} customStyles={dividerStyles} />
                    <Stack tokens={{ childrenGap: themeSpacings.s }}>
                      <Stack.Item>
                        <SocialLoginButton
                          data-testid="registration-microsoft-sso-btn"
                          buttonType={IButtonType.Microsoft}
                          tabIndex={4}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <SocialLoginButton
                          data-testid="registration-google-sso-btn"
                          buttonType={IButtonType.Google}
                          tabIndex={5}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <SocialLoginButton
                          data-testid="registration-apple-sso-btn"
                          buttonType={IButtonType.Apple}
                          tabIndex={6}
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack>
                )}
                <Stack>
                  <Stack.Item className={alreadyHaveAnAccountStyles}>
                    {splitAndStyleString(
                      t('alreadyHaveAnAccount', {
                        link: `<>${t('signIn')}</>`,
                      }),
                      /<>|<\/>/,
                      linkStyles,
                      '/',
                      7,
                      '_self',
                      'registration-email-already-have-account',
                    )}
                  </Stack.Item>
                </Stack>
              </Stack>
            </form>
          </Stack.Item>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.CreateAccount />
      </ModalBanner>
    </Modal>
  );
};

export default RegisterAccountNameAndEmail;
