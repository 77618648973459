import { createSlice } from '@reduxjs/toolkit';
import type { IServiceConfigState } from 'models';

import { ClientOs, ClientType, ServiceConfigStatus } from 'models';
import { loadInitialConfigAction } from './service-config.async-actions';

const initialState: IServiceConfigState = {
  serviceConfigStatus: ServiceConfigStatus.Undefined,
  config: {
    clientType: ClientType.WebClient,
    clientOs: ClientOs.Unknown,
    requireCaptcha: true,
    isAlibabaCaptcha: false,
    countryCode: '',
  },
  error: {
    isError: false,
    message: '',
  },
};

const serviceConfigSlice = createSlice({
  name: 'serviceConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadInitialConfigAction.pending, (state) => {
        state.serviceConfigStatus = ServiceConfigStatus.Initializing;
      })
      .addCase(loadInitialConfigAction.fulfilled, (state, action) => {
        state.serviceConfigStatus = action.payload.serviceConfigStatus;
        state.config = action.payload.config;
      })
      .addCase(loadInitialConfigAction.rejected, (state, action: any) => {
        state.serviceConfigStatus = action.payload.serviceConfigStatus;
        state.error = action.payload.error;
      });
  },
});

export const ServiceConfigActions = serviceConfigSlice.actions;
export const ServiceConfigReducer = serviceConfigSlice.reducer;
