const prodWeConfig: Partial<AppConfig> = {
  apiUrl: '/',
  defaultRedirectUri: 'https://web.teamviewer.com/authenticate/withtoken',
  webClientUri: 'https://web.teamviewer.com/',
  invisibleRecaptchaSiteKey: '6Lfw5CMhAAAAABQHrMWqCNGxgHkkB-SjlPCTjWyn',
  recaptchaSiteKey: '6Ledv-IUAAAAAHltPRfgliZXuL_tApeW1p31zuWF',
  isTestMaster: false,
  adobeDtmUrl: 'https://assets.adobedtm.com/439d99b3b8f0/eec744300fc5/launch-7638b87287c1.min.js',
  masterPublicKey: {
    kty: 'RSA',
    e: 'AQAB',
    n: 'wnytInIjAj-SKVZj3xy2tHdnkJ0upwNDPiTTqu1sLQXlBUtxdxEwFgXE7YrINSuut-BXXu9AdCND_mVjekWaycD3V5UYZHxMWvshD9gsOJBHw28oN5Rmsr0mbi8d_DbYdH1pweg7BYnCQDqJ9GqhXaW_IuUE16he8mJnbkNPJGc',
  },
};

export default prodWeConfig;
