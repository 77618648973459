import { useAdobeAnalytics, useAdobeAnalyticsPageView } from './AdobeAnalytics';
import type { AnalyticsPageActionEvent, AnalyticsUserActionEvent, PageAction, UserAction } from './events';
import { initializeGTM, useGTM, useGTMPageView } from './GTM';

export function initialize() {
  initializeGTM();
}

export function useAnalytics() {
  const { gtmAction } = useGTM();
  const { adobeAction, virtualPageView } = useAdobeAnalytics();

  return {
    analyticsAction<A extends keyof UserAction>(event: AnalyticsUserActionEvent<A>) {
      gtmAction(event);
      adobeAction(event);
    },
    virtualPageView<A extends keyof PageAction>(event: AnalyticsPageActionEvent<A>) {
      virtualPageView(event);
    },
  };
}

export function useAnalyticsPageView() {
  useGTMPageView();
  useAdobeAnalyticsPageView();
}
