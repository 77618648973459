const baseConfig = {
  apiUrl: '',
  defaultRedirectUri: 'https://trunk.web.tvwebservices.org/authenticate/withtoken',
  webClientUri: 'https://trunk.web.tvwebservices.org/',
  axe: undefined as any,
  invisibleRecaptchaSiteKey: '6LcEBCwUAAAAAJTusCi8CaG-8xchhn-bIia7qAQY',
  recaptchaSiteKey: '6Le29iQTAAAAAHdVwQ3r8CtaGT51hJM9mnIJEW6y',
  showPasswordRequirements: false,
  isTestMaster: true,
  masterPublicKey: {
    kty: 'RSA',
    e: 'AQAB',
    n: '42aAkXhpKwMr_QldLalDjSw1l2x52vQ-L15FTc6lM6SGzyJZk7KoUNpvvAS-Lk3HAtc3Y-otvEAgkwaQDoSuynmkhkoVoZGAgYqq4rWTX-bbex8VOdW9cg8wAyAb056-KDYarODqOSgphuPBq6eB-RvPUgnic7AuiKAoTkF0H4c',
  },
  gtmId: 'GTM-K2HRX4K',
  adobeDtmUrl: 'https://assets.adobedtm.com/439d99b3b8f0/eec744300fc5/launch-5a816c692502-development.min.js',
  featureFlags: {
    beta: false,
    webClientRedirect: true,
    webClient: true,
    forgotPassword: true,
    createAccount: true,
    socialLogin: true,
    tensorSso: true,
    verifyAccount: true,
    verifyDevice: true,
    twoFactorDeactivation: true,
    requestPermission: true,
  },
};

export default baseConfig;
