import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FullPageLayout } from 'components';
import { configManager } from 'config';
import {
  useAppDispatch,
  useAppSelector,
  useAuthRedirect,
  useConfirmationToast,
  useErrorMessage,
  useNavigateWithRedirect,
} from 'hooks';
import { LoginMethod, LoginStatus } from 'models';
import { useAnalytics } from 'modules/Analytics';
import Loading from 'pages/Loading';
import { authActions, ssoReturnUrlAction } from 'store/auth';
import { emailRegex } from 'utils/validationRegex';

const ReturnUrlSSO = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { error, loginStatus } = useAppSelector((state) => state.auth);
  const navigate = useNavigateWithRedirect();
  const { errorMessage, setErrorMessage, errorCode } = useErrorMessage((state) => state.auth.error);

  useEffect(() => {
    dispatch(authActions.setLoginMethod(LoginMethod.Sso));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAuthRedirect();

  const { analyticsAction } = useAnalytics();
  useEffect(() => {
    if (loginStatus === LoginStatus.ReadyToRedirect) {
      analyticsAction({ action: 'signIn', method: 'tensor' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    showError: errorMessage !== '',
    resetAction: () => setErrorMessage(''),
  });

  useEffect(() => {
    if (error.isError) {
      navigate(
        {
          pathname: '/',
          search: new URLSearchParams({
            redirect_uri: searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri'),
          }).toString(),
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const email = searchParams.get('email') || '';
    const rgx = new RegExp(emailRegex);

    if (!email || !rgx.test(email)) {
      navigate('/');
    }

    dispatch(authActions.setUsername(email));

    dispatch(
      ssoReturnUrlAction({
        SsoVerificationToken: searchParams.get('ssoVerificationToken'),
        CustomerId: searchParams.get('scid2'),
        Username: email,
        RedirectUri: searchParams.get('redirect_uri') || '',
        SsoLogoutUrl: searchParams.get('sloUrl') || '',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullPageLayout>
      <Loading />
    </FullPageLayout>
  );
};

export default ReturnUrlSSO;
