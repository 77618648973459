import type { IStackItemStyles, IStackStyles, IStackTokens, ITextStyles } from '@teamviewer/ui-library';
import { useThemes } from '@teamviewer/ui-library';

interface IOfflineScreenStyles {
  offlineScreenOverlayStyles: IStackStyles;
  offlineScreenOverlayLogoStyles: IStackStyles;
  offlineScreenContentStyles: IStackStyles;
  offlineScreenOverlayMessageStyles: IStackStyles;
  offlineScreenOverlayMessageTextStyles: IStackStyles;
  offlineScreenOverlayMessageTitleStyles: IStackStyles;
  offlineScreenOverlayToken: IStackTokens;
  titleStyles: IStackItemStyles;
  subTitleStyles: IStackItemStyles;
  linkStyles: ITextStyles;
  footerStyles: IStackItemStyles;
}

export const useOfflineScreenStyles = (): IOfflineScreenStyles => {
  const { teamViewer } = useThemes();
  return {
    offlineScreenOverlayStyles: {
      root: {
        backgroundColor: teamViewer.colors.greys.grey10,
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
    },

    offlineScreenOverlayLogoStyles: {
      root: {
        padding: `${teamViewer.spacings.xl} 0 ${teamViewer.spacing(24)} ${teamViewer.spacing(16)}`,
        boxSizing: 'border-box',
      },
    },

    offlineScreenContentStyles: {
      root: {
        overflowY: 'auto',
        paddingBottom: teamViewer.spacings.xl,
        height: 'auto',
        margin: 'auto',
        marginBottom: teamViewer.spacing(32),
        flexShrink: 0,
      },
    },

    offlineScreenOverlayMessageStyles: {
      root: {
        padding: `${teamViewer.spacing(8)}`,
        background: teamViewer.colors.greys.white,
        borderRadius: teamViewer.borderRadius.l,
        border: `1px solid ${teamViewer.colors.dividers.bodyDivider}`,
        boxShadow: `0px 0.3px 0.9px rgba(0, 0, 0, 0.05), 0px 1.6px 13.6px rgba(0, 0, 0, 0.05)`,
        fontSize: teamViewer.typography.fontSizes.size16,
        width: 606,
        color: teamViewer.colors.type.typeSecondary,
        boxSizing: 'border-box',
        '*': {
          fontSize: teamViewer.typography.fontSizes.size16,
        },
      },
    },

    offlineScreenOverlayMessageTextStyles: {
      root: {
        fontSize: teamViewer.typography.fontSizes.size16,
        fontWeight: teamViewer.typography.fontWeights.semibold,
      },
    },

    offlineScreenOverlayMessageTitleStyles: {
      root: {
        fontWeight: teamViewer.typography.fontWeights.bold,
        color: teamViewer.colors.type.typePrimary,
        fontSize: teamViewer.typography.fontSizes.size16,
      },
    },

    offlineScreenOverlayToken: {
      childrenGap: teamViewer.spacings.m,
    },

    titleStyles: {
      root: {
        color: teamViewer.colors.type.typePrimary,
        fontSize: teamViewer.typography.fontSizes.size32,
        lineHeight: teamViewer.spacing(10),
        paddingTop: teamViewer.spacing(10),
      },
    },

    subTitleStyles: {
      root: {
        color: teamViewer.colors.type.typeSecondary,
        fontSize: teamViewer.typography.fontSizes.size16,
        padding: `${teamViewer.spacings.m} ${teamViewer.spacings.sm} ${teamViewer.spacings.xxxl} ${teamViewer.spacings.sm}`,
        textAlign: 'center',
      },
    },

    linkStyles: {
      root: {
        color: teamViewer.colors.type.typeBluePrimary,
        fontWeight: teamViewer.typography.fontWeights.semibold,
      },
    },

    footerStyles: {
      root: {
        maxHeight: 38,
        backgroundColor: teamViewer.colors.type.typeWhite,
        borderTop: `1px solid ${teamViewer.colors.dividers.bodyDivider}`,
        padding: `${teamViewer.spacings.sm} ${teamViewer.spacings.xl}`,
        zIndex: '100',
      },
    },
  };
};
