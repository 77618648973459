import { useEffect, useRef, useState } from 'react';
import type { AuthCodeRef } from 'react-auth-code-input';
import AuthCode from 'react-auth-code-input';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { mergeClasses, Spinner, SpinnerSize, Stack, TextPrimary } from '@teamviewer/ui-library';

import { configManager } from 'config';
import {
  useAppDispatch,
  useAppSelector,
  useAuthRedirect,
  useConfirmationToast,
  useErrorMessage,
  useNavigateWithRedirect,
} from 'hooks';
import { LoginStatus, ResetPasswordStatus, TfaFor } from 'models';
import { authActions, resetPasswordActions, resetPasswordTfaAction, tfaAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';
import type { ILocationState } from '../TwoFactor';

import { useStyles } from 'pages/TwoFactor/TwoFactorForm/TwoFactorForm.styles';

export const TwoFactorForm = () => {
  const { t } = useTranslation(['twofactor', 'resetpassword']);
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();
  const locationState = useLocation().state as ILocationState;
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const { accountInfo, loginStatus, isLoginInProgress, error: loginError } = useAppSelector((state) => state.auth);
  const { status: resetPasswordStatus, error: resetPasswordError } = useAppSelector((state) => state.resetPasswod);
  const { errorMessage: resetPasswodErrorMessage } = useErrorMessage((state) => state.resetPasswod.error);
  const [securityCode, setSecurityCode] = useState<string>('');
  const [searchParams] = useSearchParams();
  const isError =
    (locationState.tfaFor === TfaFor.Login && loginError.isError) ||
    (locationState.tfaFor === TfaFor.ResetPassword && resetPasswordError.isError);
  const isLoading =
    (locationState.tfaFor === TfaFor.Login && isLoginInProgress) ||
    (locationState.tfaFor === TfaFor.ResetPassword && resetPasswordStatus === ResetPasswordStatus.InProgress);
  const isSuccess =
    locationState.tfaFor === TfaFor.ResetPassword && resetPasswordStatus === ResetPasswordStatus.Successed;
  const inputLength = 6;

  useConfirmationToast({
    successProps: {
      icon: 'SendIcon',
      message: t('resetpassword:passwordSaved'),
    },
    errorProps: {
      icon: 'WarningIcon',
      message: resetPasswodErrorMessage || t('invalidCode'),
    },
    resetAction: () => {
      dispatch(authActions.setError({ isError: false }));
      dispatch(resetPasswordActions.setError({ isError: false }));
      AuthInputRef.current?.clear();
    },
    showError: isError,
    showSuccess: isSuccess,
  });

  const handleOnChange = (res: string) => {
    setSecurityCode(res);
  };

  const {
    authCodeContainerStyles,
    authCodeErrorInputStyles,
    authCodeInputStyles,
    twoFactorFormSectionStyles,
    twoFactorLoadingSectionStyles,
    twoFactorLoadingTextStyles,
    twoFactorResendStyles,
  } = useStyles();

  const { linkStyles } = useLinkStyles();

  const handleSubmit = () => {
    switch (locationState.tfaFor) {
      case TfaFor.Login:
        dispatch(
          tfaAction({
            tfaSecurityCode: securityCode,
          }),
        );
        break;
      case TfaFor.ResetPassword:
        dispatch(
          resetPasswordTfaAction({
            tfaCode: securityCode,
            token: locationState.params.token,
            password: locationState.params.password,
            recoveryCode: locationState.params.recoveryCode,
          }),
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (securityCode.length === inputLength) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityCode]);

  useEffect(() => {
    if (locationState.tfaFor === TfaFor.Login && loginStatus === LoginStatus.VerifyAccountPending) {
      navigate(
        {
          pathname: '/verify-account',
          search: new URLSearchParams({
            email: accountInfo.username,
          }).toString(),
        },
        {
          replace: true,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState, loginStatus]);

  useEffect(() => {
    if (
      (locationState.tfaFor === TfaFor.Login && loginStatus === LoginStatus.Failed) ||
      (locationState.tfaFor === TfaFor.ResetPassword &&
        (resetPasswordStatus === ResetPasswordStatus.Successed ||
          resetPasswordStatus === ResetPasswordStatus.AuthenticationFailed))
    ) {
      navigate(
        {
          pathname: '/',
          search: new URLSearchParams({
            redirect_uri: searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri'),
          }).toString(),
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState, loginStatus, resetPasswordStatus]);

  useAuthRedirect();

  return (
    <Stack data-testid="signin-2fa-form" className={twoFactorFormSectionStyles}>
      <AuthCode
        ref={AuthInputRef}
        containerClassName={authCodeContainerStyles}
        inputClassName={mergeClasses(authCodeInputStyles, isError && authCodeErrorInputStyles)}
        length={inputLength}
        onChange={handleOnChange}
        disabled={isLoading}
        allowedCharacters="numeric"
      />
      <Stack.Item className={twoFactorResendStyles}>
        {splitAndStyleString(
          t('troubleshooting', {
            link: `<>${t('troubleshootingLink')}</>`,
          }),
          /<>|<\/>/,
          linkStyles,
          'https://community.teamviewer.com/English/kb/articles/109768-sign-in-with-your-account#how-to-sign-in-to-your-account-via-two-factor-authentication-(recommended) ',
          undefined,
          '_blank',
          'signin-tfa-troubleshooting',
          isLoading,
        )}
      </Stack.Item>
      {isLoading && (
        <Stack className={twoFactorLoadingSectionStyles} horizontal>
          <Spinner size={SpinnerSize.medium} />
          <TextPrimary variant="medium" className={twoFactorLoadingTextStyles}>
            {t('verifying')}
          </TextPrimary>
        </Stack>
      )}
    </Stack>
  );
};
