import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Stack, TextPrimary } from '@teamviewer/ui-library';

import hideIf from './hideIf';

const DevPortal = ({ children }: PropsWithChildren<Record<never, any>>) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const container = document.createElement('nav');
  container.classList.add('dev-portal');

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(
    <Stack
      styles={{
        root: {
          position: 'fixed',
          top: 0,
          right: 0,
          backgroundColor: 'orange',
          opacity: '80%',
        },
      }}
    >
      <Stack
        tokens={{ childrenGap: 5, padding: 5 }}
        styles={{ root: { padding: 5 } }}
        horizontalAlign="space-between"
        verticalAlign="center"
        horizontal
      >
        <TextPrimary>Dev Menu</TextPrimary>
        <button style={{ width: 24, height: 24 }} onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? '-' : '+'}
        </button>
      </Stack>
      {isExpanded && <Stack tokens={{ childrenGap: 5, padding: 5 }}>{children}</Stack>}
    </Stack>,
    container,
  );
};

export default hideIf(process.env.NODE_ENV === 'production', DevPortal);
