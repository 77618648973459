import { makeStyles } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        whiteSpace: 'pre-line',
      },
      optionalTextStyles: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        fontSize: tokens.typography.fontSizes.size14,
        fontWeight: tokens.typography.fontWeights.regular,
      },
      subTextStyles: {
        marginTop: tokens.spacings.xl,
      },
      checkboxStyles: {
        marginTop: tokens.spacing(6),
      },
      descriptionTextStyles: {
        lineHeight: tokens.spacing(5),
        [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
          textAlign: 'center',
        },
      },
      emailTextStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(5),
        color: tokens.colors.type.typePrimary,
      },
      buttonStyles: {
        marginTop: tokens.spacings.l,
      },
    }) as const,
);
