import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { i18nextToTeamViewerLanguageCode } from '@teamviewer/tv-i18next';
import {
  CheckboxFormInput,
  DirectionalHint,
  PasswordFormInput,
  Stack,
  TextPrimary,
  TextSecondary,
  themeSpacings,
} from '@teamviewer/ui-library';
import i18n from 'i18n';

import { Banner, IconLink, LinkWithRedirect, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import InfoIconWithTextAndTooltip from 'components/InfoIconWithTextAndTooltip';
import { configManager } from 'config';
import {
  useAppDispatch,
  useAppSelector,
  useAuthRedirect,
  useConfirmationToast,
  useErrorMessage,
  useFormWithCaptcha,
  useNavigateWithRedirect,
  useTracking,
} from 'hooks';
import { usePasswordStrengthMessages } from 'hooks/usePasswordStrengthMessages.hook';
import { RegisterAccountStatus } from 'models';
import { useAnalytics } from 'modules/Analytics';
import { authActions, getSsoVerificationToken, loginAction, registerAccountAsyncAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { getCheckBoxRequiredRules } from 'utils/validationRules';

import { useStyles } from './SocialLoginRegisterForm.styles';

const SocialLoginRegister = () => {
  const { t } = useTranslation(['socialloginregister', 'sociallogin', 'banner']);
  const { trackAction, trackView } = useTracking();
  const isLoading = useAppSelector(
    (state) => state.registerAccount.registerAccountStatus === 'InProgress' || state.auth.isLoginInProgress,
  );
  const registerAccountStatus = useAppSelector((state) => state.registerAccount.registerAccountStatus);
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const name = searchParams.get('name');
  const idProvider = searchParams.get('id_provider') || '';
  const idToken = searchParams.get('id_token');
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const { errorMessage, setErrorMessage, errorCode } = useErrorMessage(
    (state) => state.registerAccount.error || state.auth.error,
    { defaultMessageCreator: () => t('createAccountError') },
  );
  const { analyticsAction, virtualPageView } = useAnalytics();
  const languageCode = i18nextToTeamViewerLanguageCode(i18n.language);
  const passwordStrengthMessages = usePasswordStrengthMessages();

  const { formSectionStyles, subtitleStyles, titleStyles } = useStyles();
  const { linkStyles } = useLinkStyles();

  useEffect(() => {
    trackView('social-login-create-account-dialog', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    virtualPageView({ action: 'sign_up', method: 'set_encryption_key_input' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (registerAccountStatus === RegisterAccountStatus.Successed) {
      if (idProvider.toLowerCase() === 'google') {
        analyticsAction({ action: 'signUp', method: 'google' });
      } else if (idProvider.toLowerCase() === 'microsoft') {
        analyticsAction({ action: 'signUp', method: 'microsoft' });
      } else if (idProvider.toLowerCase() === 'apple') {
        analyticsAction({ action: 'signUp', method: 'apple' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerAccountStatus]);

  useConfirmationToast({
    successProps: {
      icon: 'SendIcon',

      message: successMessage,
    },
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => {
      setSuccessMessage('');
      setErrorMessage('');
    },
    showSuccess: successMessage !== '',
    showError: errorMessage !== '',
  });

  const {
    control,
    formState: { isValid },
    recaptchaLoaded,
    captchaComponent,
    executeRecaptcha,
  } = useFormWithCaptcha<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    async onSubmit({ encryptionKey, captchaResponse }) {
      trackAction('user-account', 'social-login-create-account');

      if (name === null || email === null || idToken === null) {
        dispatch(authActions.setError({ isError: true, messageCreator: () => t('missingData') }));
        return;
      }

      const { payload } = await dispatch(
        registerAccountAsyncAction({
          payload: {
            DisplayName: name,
            Email: email,
            Password: encryptionKey,
            SocialLoginIdentityToken: idToken,
            RedirectUri: redirectUri,
            'invisiblecaptcha-response': captchaResponse,
          },
          t,
        }),
      );

      if (payload?.registerAccountStatus === RegisterAccountStatus.Successed) {
        setSuccessMessage('Your account has been created successfully');

        // Start automatic login after successfully creating the account
        const redirectUri = searchParams.get('redirect_uri') as string;

        try {
          const ssoPayload = await dispatch(getSsoVerificationToken(email)).unwrap();

          await dispatch(
            loginAction({
              username: email,
              password: encryptionKey,
              redirectUri,
              socialLoginIdentityToken: idToken,
              ssoVerificationToken: ssoPayload,
              'invisiblecaptcha-response': captchaResponse,
            }),
          ).unwrap();
        } catch (_error) {
          navigate({ pathname: '/sociallogin/signin', search: searchParams.toString() });
        }
      }
    },
  });

  useAuthRedirect();

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <IconLink
            iconName="Back"
            link={`/sociallogin/register?${searchParams.toString()}`}
            tabIndex={1}
            disabled={isLoading}
            data-testid="registration-third-party-go-back-link"
          >
            <TextPrimary as="h1" block data-testid="registration-third-party-title" className={titleStyles}>
              {t('title')}
            </TextPrimary>
          </IconLink>
          <TextSecondary
            variant="medium"
            className={subtitleStyles}
            nowrap
            block
            data-testid="registration-third-party-subtitle"
          >
            {t('subtitle')}
          </TextSecondary>
          {captchaComponent}
          <Stack.Item>
            <Stack
              as="form"
              className={formSectionStyles}
              data-testid="registration-third-party-key-form"
              onSubmit={executeRecaptcha}
              tokens={{ childrenGap: themeSpacings.l }}
            >
              <Stack.Item>
                <PasswordFormInput
                  id="encryptionKey-input"
                  disabled={isLoading}
                  control={control}
                  name="encryptionKey"
                  autoComplete="current-encryptionKey"
                  tabIndex={3}
                  autoFocus
                  inputMode="text"
                  label={t('encryptionKey')}
                  ariaLabel={t('encryptionKey')}
                  passwordStrengthMessages={passwordStrengthMessages}
                  data-testid="registration-third-party-key-input"
                />
              </Stack.Item>
              <Stack tokens={{ childrenGap: themeSpacings.l }}>
                <Stack.Item>
                  <Stack horizontal tokens={{ childrenGap: themeSpacings.xs }}>
                    <CheckboxFormInput
                      name="acceptedToS"
                      control={control}
                      rules={getCheckBoxRequiredRules()}
                      ariaLabel={t('checkboxAriaLabel')}
                      tabIndex={4}
                      onRenderLabel={() => (
                        <span>
                          <Trans
                            ns="common"
                            i18nKey="acceptEULAAndDPA"
                            components={{
                              link1: (
                                <LinkWithRedirect
                                  className={linkStyles}
                                  href={`https://www.teamviewer.com/${languageCode}/eula/`}
                                  target="_blank"
                                  disabled={isLoading}
                                />
                              ),
                              link2: (
                                <LinkWithRedirect
                                  className={linkStyles}
                                  href={`https://www.teamviewer.com/${languageCode}/eula/#dpa`}
                                  target="_blank"
                                  disabled={isLoading}
                                />
                              ),
                              link3: (
                                <LinkWithRedirect
                                  className={linkStyles}
                                  href="https://www.teamviewer.com/link/?url=180010"
                                  target="_blank"
                                  disabled={isLoading}
                                />
                              ),
                            }}
                            shouldUnescape
                            tOptions={{ interpolation: { escapeValue: true } }}
                          />
                        </span>
                      )}
                      disabled={isLoading}
                      data-testid="registration-third-party-tos-checkbox"
                    />
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <SubmitButton
                    isValid={isValid}
                    isLoading={isLoading || !recaptchaLoaded}
                    isCheckmarkVisible={false}
                    data-testid="registration-third-party-submit-btn"
                    tabIndex={4}
                    label={t('createAccount')}
                  />
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack.Item>
          <InfoIconWithTextAndTooltip
            showTooltip
            tooltipDirection={DirectionalHint.rightCenter}
            tooltipContent={t('newsletterTooltip')}
            content={
              <Trans
                ns="socialloginregister"
                i18nKey="newsletterSubscriptionInfo"
                components={{
                  link1: (
                    <LinkWithRedirect
                      className={linkStyles}
                      href="https://www.teamviewer.com/link/?url=180010"
                      target="_blank"
                      disabled={isLoading}
                    />
                  ),
                }}
                shouldUnescape
                tOptions={{ interpolation: { escapeValue: true } }}
              />
            }
          />
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.SocialLoginRegister service={idProvider} />
      </ModalBanner>
    </Modal>
  );
};

export default SocialLoginRegister;
