import { useTranslation } from 'react-i18next';
import { Image, Stack, TextSecondary } from '@teamviewer/ui-library';

import { FullPageLayout } from 'components';

import { useStyles } from './ErrorScreen.styles';

const ErrorScreen = () => {
  const { t } = useTranslation('errorscreen');
  const { containerStyles, helpStyles, imgStyle } = useStyles();

  return (
    <FullPageLayout data-testid="not-found-container">
      <Stack className={containerStyles}>
        <Image
          src="/assets/laptop_frontal_error.svg"
          className={imgStyle}
          alt={t('imgAccessibility')}
          data-testid="not-found-image"
        />
        <FullPageLayout.Title data-testid="not-found-title">{t('title')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="not-found-subtitle">{t('explanation')}</FullPageLayout.Description>
        <Stack>
          <TextSecondary data-testid="not-found-help-text" className={helpStyles}>
            {t('help')}
          </TextSecondary>
        </Stack>
      </Stack>
    </FullPageLayout>
  );
};

export default ErrorScreen;
