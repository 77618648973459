import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  CheckboxFormInput,
  Stack,
  TextButton,
  TextPrimary,
  TextSecondary,
  themeSpacings,
} from '@teamviewer/ui-library';
import type { BackendError } from 'models';

import { api } from 'api';
import { Banner, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import { configManager } from 'config';
import { useAppDispatch, useAppSelector, useConfirmationToast, useErrorMessage, useNavigateWithRedirect } from 'hooks';
import { useStayTunedDialog } from 'hooks/use-stay-tuned-dialog';
import { LoginMethod, LoginStatus, NoBackendError } from 'models';
import { authActions } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { getCheckBoxRequiredRules } from 'utils/validationRules';

import { useStyles } from './VerifyAccount.styles';

const VerifyAccount = () => {
  const { t } = useTranslation(['verifyaccount', 'banner', 'toastMessage']);
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();
  const featureFlags = configManager.get('featureFlags');
  const { render: renderStayTunedDialog, toggle: toggleStayTunedDialog } = useStayTunedDialog();
  const {
    control,
    formState: { isValid },
  } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const redirectUri = searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const { loginStatus, loginMethod } = useAppSelector((state) => state.auth);
  const isSsoLogin = loginMethod === LoginMethod.Sso;

  const [localError, setLocalError] = useState<BackendError>(NoBackendError);
  const { errorMessage, errorCode, setErrorMessage } = useErrorMessage(() => localError);

  const { checkboxStyles, optionalTextStyles, titleStyles } = useStyles();
  const { linkStyles } = useLinkStyles();

  useConfirmationToast({
    successProps: {
      icon: 'SendIcon',

      message: successMessage,
    },
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => {
      setSuccessMessage('');
      setLocalError(NoBackendError);
    },
    showSuccess: successMessage !== '',
    showError: errorMessage !== '',
  });

  useEffect(() => {
    if (!email || loginStatus !== LoginStatus.VerifyAccountPending) {
      navigate('/');
    }
  }, [email, loginStatus, navigate]);

  const resendVerificationEmail = () => {
    if (!featureFlags.verifyAccount) {
      toggleStayTunedDialog();
      return;
    }

    api
      .post('/account/resendvalidationemail', {
        email,
        RedirectUri: redirectUri,
      })
      .then((response) => {
        if (response.data?.s) {
          setSuccessMessage(t('toastMessage:emailSentSuccess', { email }));
        } else {
          setLocalError({
            isError: true,
            errorCode: response?.data?.ec,
            messageCreator: generateStringIdForErrorMessage({
              responseCodeType: response?.data?.t,
              responseCode: response?.data?.e,
            }),
          });
        }
      })
      .catch(() => {
        setErrorMessage(t('requestReactivateAccountFailed'));
      });
  };

  return (
    <Modal>
      <ModalMain>
        {renderStayTunedDialog()}
        <Stack tokens={{ childrenGap: themeSpacings.s }}>
          <Stack.Item>
            <TextPrimary data-testid="verify-account-title" className={titleStyles}>
              {t('verifyAccountTitle')}
            </TextPrimary>
          </Stack.Item>
          <Stack.Item>
            <TextSecondary variant="medium" data-testid="verify-account-subtitle">
              {t('verifyAccountDescription')}
            </TextSecondary>
          </Stack.Item>
          <Stack.Item>
            <CheckboxFormInput
              className={checkboxStyles}
              name="hasVerifiedAccount"
              control={control}
              label={t('hasVerifiedAccountCheckbox')}
              ariaLabel={t('hasVerifiedAccountCheckbox')}
              rules={getCheckBoxRequiredRules()}
              tabIndex={1}
              data-testid="verify-account-done-checkbox"
            />
          </Stack.Item>
          <SubmitButton
            isValid={isValid}
            isLoading={false}
            isCheckmarkVisible={false}
            label={t('signIn')}
            onClick={() => {
              dispatch(authActions.setLoginStatus(LoginStatus.ReadyForLogin));
              if (isSsoLogin) {
                dispatch(authActions.setUsername(''));
              }
              navigate(isSsoLogin ? `/` : `/?email=${searchParams.get('email') || ''}`, { replace: true });
            }}
            tabIndex={2}
            data-testid="verify-account-signin-btn"
          />
          <Stack>
            <TextPrimary variant="medium" data-testid="verify-account-spam-folder-text" className={optionalTextStyles}>
              {t('checkSpamFolder')}
            </TextPrimary>
            <TextButton
              data-testid="verify-account-resend-email-link"
              tabIndex={3}
              onClick={resendVerificationEmail}
              className={linkStyles}
            >
              {t('sendVerification')}
            </TextButton>
          </Stack>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.CreateAccount />
      </ModalBanner>
    </Modal>
  );
};

export default VerifyAccount;
