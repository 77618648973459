import { Trans } from 'react-i18next';
import { Stack, TeamViewerLogo, TextButton } from '@teamviewer/ui-library';
import type { TFunction } from 'i18next';

import StatusBar from 'components/OfflineScreen/StatusBar';
import { ReactComponent as OfflineLaptop } from '../../assets/offline_laptop.svg';

import { useOfflineScreenStyles } from 'components/OfflineScreen/OfflineScreen.styles';

export interface OfflineScreenProps {
  t: TFunction<'translation', undefined>;
  isNativeClient: boolean;
}

export const OfflineScreen = ({ t, isNativeClient }: OfflineScreenProps) => {
  const {
    footerStyles,
    offlineScreenContentStyles,
    offlineScreenOverlayLogoStyles,
    offlineScreenOverlayMessageStyles,
    offlineScreenOverlayStyles,
    subTitleStyles,
    titleStyles,
  } = useOfflineScreenStyles();

  return (
    <>
      <Stack styles={offlineScreenOverlayStyles} data-testid="offline-screen-wrapper">
        {!isNativeClient && (
          <Stack>
            <Stack.Item styles={offlineScreenOverlayLogoStyles} grow={1}>
              <TeamViewerLogo variant="withText" />
            </Stack.Item>
          </Stack>
        )}
        <Stack styles={offlineScreenContentStyles} horizontalAlign="center" verticalAlign="center" grow={1}>
          <Stack.Item>
            <OfflineLaptop />
          </Stack.Item>
          <Stack.Item styles={titleStyles}>{t('title')}</Stack.Item>
          <Stack.Item styles={subTitleStyles}>
            {t('subtitle')}
            <br />
            <Trans
              i18nKey={t('white_box.line2')}
              components={{
                link1: (
                  <TextButton
                    target="_blank"
                    href="https://www.teamviewer.com/link/?url=546414"
                    rel="noopener noreferrer"
                  />
                ),
                link2: (
                  <TextButton
                    target="_blank"
                    href="https://www.teamviewer.com/link/?url=260672"
                    rel="noopener noreferrer"
                  />
                ),
              }}
              shouldUnescape
              tOptions={{ interpolation: { escapeValue: true } }}
            />
          </Stack.Item>
          <Stack.Item styles={offlineScreenOverlayMessageStyles} />
        </Stack>
        {isNativeClient && (
          <Stack styles={footerStyles} grow={1}>
            <StatusBar t={t} />
          </Stack>
        )}
      </Stack>
    </>
  );
};
