import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { configManager } from 'config';
import { useAppDispatch, useAppSelector, useAuthRedirect, useConfirmationToast, useErrorMessage } from 'hooks';
import { LoginMethod, LoginStatus } from 'models';
import { authActions, getAccountLoginMethodAction } from 'store';
import { emailRegex } from 'utils/validationRegex';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

const Login = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { loginStatus, loginMethod } = useAppSelector((state) => state.auth);
  const email = useAppSelector((state) => state.auth.accountInfo.username) || searchParams.get('email');
  const isSocialLogin = searchParams.get('is_social_login')?.toLowerCase() === 'true';
  const redirectUri = searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const { errorMessage, errorCode } = useErrorMessage((state) => state.auth.error);

  useAuthRedirect();

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => {
      dispatch(
        authActions.setError({
          isError: false,
        }),
      );
    },
    showError: errorMessage !== '',
  });

  useEffect(() => {
    if (!email || loginStatus !== LoginStatus.ReadyForLogin || loginMethod !== LoginMethod.NotSet || isSocialLogin) {
      return;
    }

    const rgx = new RegExp(emailRegex);
    if (!rgx.test(email)) {
      return;
    }

    dispatch(getAccountLoginMethodAction({ username: email, redirectUri }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, loginStatus, loginMethod]);

  return loginMethod === LoginMethod.UsernameAndPassword ? <PasswordForm /> : <EmailForm />;
};

export default Login;
