import axios from 'axios';

import { configManager } from 'config';

const API_URL = configManager.get('apiUrl');
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json, text/javascript, */*; q=0.01',
  },
});

export default api;
