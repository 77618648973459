import { Buffer } from 'buffer';
import type { BigInteger } from 'jsbn';
import type { IEncryptedSecretKeyWithPasswordResult } from 'models';

import { BCommandClass, convertBCommandToByteArray, createBCommand, SsoDataCommandTypes } from 'utils/bCommand';
import * as Encryption from './Encryption';
import { saltSize } from './Encryption/Parameters';
import { BigIntegerToUint8Array, Uint8ArrayToBigInteger } from './Encryption/utilities';

export function loginAuthenticateChallenge(username: string, password: string) {
  if (username.length === 0) {
    throw new Error('Username cannot be empty!');
  }

  if (password.length === 0) {
    throw new Error('Password cannot be empty!');
  }

  const a = Encryption.Srp.generateClientSecretEphemeral_a();
  const publicKey = Encryption.Srp.calculateClientPublicEphemeral_A(a);

  return {
    clientSecretEphemeral: a,
    publicKey: Encryption.Utilities.BigIntegerToBase64(publicKey),
  };
}

export async function loginAuthenticateVerify(
  accountId: string,
  password: string,
  salt: string,
  a: BigInteger,
  serverChallenge: string,
  keysMapIn: Map<string, string>,
) {
  if (accountId.length === 0 || password.length === 0 || salt.length < Encryption.Parameters.saltSize) {
    throw new Error('User data is incomplete.');
  }

  if (!Encryption.Srp.isServerPublicEphemeral_BValid(serverChallenge)) {
    throw new Error('Faild to complete authentication.');
  }

  const B = Encryption.Utilities.Base64ToUint8Array(serverChallenge);
  const s = Encryption.Utilities.Base64ToUint8Array(salt);

  const BBigIntger = Encryption.Utilities.Base64ToBigInteger(serverChallenge);

  const u = await Encryption.Srp.calculate_u(B);
  const uBigIntger = Uint8ArrayToBigInteger(u);

  const x = await Encryption.Srp.calculateClientPrivateKey_x(accountId, password, s);
  const keys = await Encryption.Srp.calculateSessionKey_K(BBigIntger, x, a, uBigIntger, keysMapIn);

  const secret = Buffer.from(keys.K).toString('base64');

  return {
    secret,
    keysMapOut: keys.keysMapOut,
  };
}

export async function decryptSsoLoginTokenSecret(encryptedSsoLoginTokenSecret: string, customerId: string) {
  const ssoLoginTokenSecretByteArray = await Encryption.decryptSsoLoginTokenSecret(
    new Uint8Array(Buffer.from(encryptedSsoLoginTokenSecret, 'base64')),
    new TextEncoder().encode(customerId),
  );
  const bCommand = createBCommand(ssoLoginTokenSecretByteArray);

  if (
    bCommand.CommandClass !== BCommandClass.SsoDataCommand ||
    bCommand.CommandType !== SsoDataCommandTypes.LoginTokenDataSerialized
  ) {
    throw new Error('Invalid command class/type of serialized SSO login token.');
  }

  const tokenSecretByteArray = bCommand.Parameters.get(1);

  // Delete last two bytes (create an empty space when converting to string).
  return new TextDecoder('utf-16le').decode(tokenSecretByteArray?.slice(0, tokenSecretByteArray?.byteLength - 2));
}

export async function encryptSsoLoginTokenSecret(ssoLoginTokenSecret: string, customerId: string) {
  // Add two bytes to match with decryption.
  const tokenSecretByteArray = Buffer.concat([Buffer.from(ssoLoginTokenSecret, 'utf16le'), Buffer.from([0, 0])]);
  const ssoLoginTokenSecretByteArray = convertBCommandToByteArray({
    CommandClass: BCommandClass.SsoDataCommand,
    CommandType: SsoDataCommandTypes.LoginTokenDataSerialized,
    Parameters: new Map<number, Uint8Array>().set(1, tokenSecretByteArray),
  });

  return Buffer.from(
    await Encryption.encryptSsoLoginTokenSecret(ssoLoginTokenSecretByteArray, new TextEncoder().encode(customerId)),
  ).toString('base64');
}

export async function generateSecretKeyWithToken(accountId: string, password: string, encryptedAccountSecret: string) {
  const { headerSize, iterations, keySize, salt, encryptedData } =
    Encryption.unwrapEncryptedAccountSecret(encryptedAccountSecret);

  const passwordByteArray = new TextEncoder().encode(password);
  const secretKey = await Encryption.decryptAccountKey(passwordByteArray, salt, iterations, keySize, encryptedData);

  const loginToken = Encryption.Utilities.generateRandomBytes(Encryption.Parameters.loginTokenSize); // The loginToken which used to encrypt the accounntKey/accountData
  const loginTokenEncodedBase64url = Encryption.Utilities.Base64ToBase64url(Buffer.from(loginToken).toString('base64'));
  const loginTokenByteArray = new TextEncoder().encode(loginTokenEncodedBase64url);

  const loginTokenSecret = Encryption.Utilities.generateRandomBytes(Encryption.Parameters.loginTokenSecretSize); // When needed, it's used to deleted the loginToken
  const salt16 = Encryption.Utilities.generateRandomBytes(16);
  const newIv = Encryption.Utilities.generateRandomBytes(16);

  // Calculate verifier.
  const salt8 = Encryption.Utilities.generateRandomBytes(8);
  const x = await Encryption.Srp.calculateClientPrivateKey_x(accountId, loginTokenEncodedBase64url, salt8);
  const verifier = Encryption.Srp.calculateVerifier(x);
  const verifierByteArray = Encryption.Utilities.BigIntegerToUint8Array(verifier);
  const paddedVerifierByteArray = Encryption.Utilities.leftPadByteArray(verifierByteArray, 256);

  const encryptedSecretKeyWithToken = await Encryption.encryptAccountKey(
    loginTokenByteArray,
    salt16,
    iterations,
    keySize,
    newIv,
    secretKey,
  );

  const encryptedAccountSecretWithToken = Encryption.wrapEncryptedAccountSecret(
    encryptedSecretKeyWithToken,
    newIv,
    headerSize,
    iterations,
    keySize,
    salt16,
  );

  const accountKeyBase64 = Buffer.from(encryptedAccountSecretWithToken).toString('base64');
  const tokenSaltBase64 = Buffer.from(salt8).toString('base64');
  const loginTokenSecretBase64 = Buffer.from(loginTokenSecret).toString('base64');
  const tokenVerifierBase64 = Buffer.from(paddedVerifierByteArray).toString('base64');

  return {
    accountKey: accountKeyBase64,
    tokenSalt: tokenSaltBase64,
    loginToken: loginTokenEncodedBase64url,
    loginTokenSecret: loginTokenSecretBase64,
    tokenVerifier: tokenVerifierBase64,
  };
}

export async function generateAccountKeysOnRegister(password: string) {
  if (password.length === 0) {
    throw new Error('Password cannot be empty!');
  }
  const crypto = window.crypto.subtle;

  const accountKeyPair = await crypto.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 4096,
      // publicExponent=65537 since native client exponent is not supported by WebCryptoAPI
      // results into 2 extra bytes in key size
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: 'SHA-1',
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const exportedAccountPrivateKeyByteArray = await Encryption.exportAccountKey(accountKeyPair.privateKey, 3);
  const exportedAccountPublicKeyByteArray = await Encryption.exportAccountKey(accountKeyPair.publicKey, 1);

  const passwordByteArray = new TextEncoder().encode(password); // utf8

  const newSalt16 = Encryption.Utilities.generateRandomBytes(16);
  const newIv16 = Encryption.Utilities.generateRandomBytes(16);
  const iterations = 2048; // increment will impact performance
  const keySize = 16;

  const encryptedAccountPrivateKey = await Encryption.encryptAccountKey(
    passwordByteArray,
    newSalt16,
    iterations,
    keySize,
    newIv16,
    exportedAccountPrivateKeyByteArray,
  );

  const headerSize = 8;
  const wrappedAccountPrivateKey = Encryption.wrapEncryptedAccountSecret(
    encryptedAccountPrivateKey,
    newIv16,
    headerSize,
    iterations,
    keySize,
    newSalt16,
  );

  // Group Key
  const accountGroupKey = await crypto.generateKey(
    {
      name: 'AES-CBC',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const exportedAccountGroupKeyByteArray = await Encryption.exportSymmetricKey(accountGroupKey, 2);
  const encryptedAccountGroupKey = await Encryption.encryptMaterialWithPublicKey(
    accountKeyPair.publicKey,
    exportedAccountGroupKeyByteArray,
    'RSA-OAEP',
  );

  // Sheltered Key
  const masterPublicKey = await Encryption.importMasterPublicKey();
  const masterKeyModulusChunks = await Encryption.Utilities.getKeyModulusChunksCount();
  const encrypteShelteredKey = await Encryption.encryptMaterialWithMasterPublicKey(
    masterPublicKey,
    exportedAccountPrivateKeyByteArray,
    'RSA-OAEP',
    masterKeyModulusChunks,
    128,
  );

  // Meeting Key
  const accountMeetingKey = await crypto.generateKey(
    {
      name: 'AES-CBC',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const exportedAccountMeetingKeyByteArray = await Encryption.exportSymmetricKey(accountMeetingKey, 2);
  const encryptedAccountMeetingKey = await Encryption.encryptMaterialWithPublicKey(
    accountKeyPair.publicKey,
    exportedAccountMeetingKeyByteArray,
    'RSA-OAEP',
  );

  let privateKey = '';
  let publicKey = '';
  let groupKey = '';
  let shelteredKey = '';
  let meetingKey = '';

  try {
    privateKey = Buffer.from(wrappedAccountPrivateKey).toString('base64');
    publicKey = Buffer.from(exportedAccountPublicKeyByteArray).toString('base64');
    groupKey = Buffer.from(encryptedAccountGroupKey).toString('base64');
    shelteredKey = Buffer.from(encrypteShelteredKey).toString('base64');
    meetingKey = Buffer.from(encryptedAccountMeetingKey).toString('base64');
  } catch (error: any) {
    throw new Error('Cannot serialize account keys');
  }

  return {
    privateKey,
    publicKey,
    groupKey,
    shelteredKey,
    meetingKey,
  };
}

export async function generateAccountPasswordSaltAndVerifier(accountId: string, password: string) {
  if (accountId.length === 0) {
    throw new Error('AccountId cannot be empty!');
  }

  if (password.length === 0) {
    throw new Error('Password cannot be empty!');
  }

  // Generate random salt (s).
  const salt = Encryption.Utilities.generateRandomBytes(saltSize);

  // Calculate client verifier (v).
  const x = await Encryption.Srp.calculateClientPrivateKey_x(accountId, password, salt);
  const verifierBigIntger = Encryption.Srp.calculateVerifier(x);
  const verifier = BigIntegerToUint8Array(verifierBigIntger);

  return { salt, verifier };
}

export async function reEncryptShelteredKeyWithPassword(
  recoveryCode: string,
  accountId: string,
  password: string,
  encryptedAccountKey: string,
) {
  if (password.length === 0) {
    throw new Error('Password cannot be empty!');
  }
  const { headerSize, iterations, keySize, salt, encryptedData } =
    Encryption.unwrapEncryptedAccountSecret(encryptedAccountKey);

  const recoveryCodeByteArray = new TextEncoder().encode(recoveryCode);
  const secretKey = await Encryption.decryptAccountKey(recoveryCodeByteArray, salt, iterations, keySize, encryptedData);

  return encryptSecretKeyWithPassword(accountId, password, secretKey, iterations, keySize, headerSize);
}

export async function encryptSecretKeyWithPassword(
  accountId: string,
  password: string,
  accountSecretKey: Uint8Array,
  iterations: number,
  keySize: number,
  headerSize: number,
) {
  if (password.length === 0) {
    throw new Error('Password cannot be empty!');
  }
  const passwordByteArray = new TextEncoder().encode(password); // utf8
  const newSalt16 = Encryption.Utilities.generateRandomBytes(16);
  const newIv16 = Encryption.Utilities.generateRandomBytes(16);

  const encryptedPrivateKeyWithPassword = await Encryption.encryptAccountKey(
    passwordByteArray,
    newSalt16,
    iterations,
    keySize,
    newIv16,
    accountSecretKey,
  );

  const encryptedAccountSecretWithToken = Encryption.wrapEncryptedAccountSecret(
    encryptedPrivateKeyWithPassword,
    newIv16,
    headerSize,
    iterations,
    keySize,
    newSalt16,
  );

  // new password salt and verifier
  const salt8 = Encryption.Utilities.generateRandomBytes(8);
  const x = await Encryption.Srp.calculateClientPrivateKey_x(accountId, password, salt8);
  const verifier = Encryption.Srp.calculateVerifier(x);
  const verifierByteArray = Encryption.Utilities.BigIntegerToUint8Array(verifier);
  const paddedVerifierByteArray = Encryption.Utilities.leftPadByteArray(verifierByteArray, 256);

  const accountKeyBase64 = Buffer.from(encryptedAccountSecretWithToken).toString('base64');
  const newSaltBase64 = Buffer.from(salt8).toString('base64');
  const newVerifierBase64 = Buffer.from(paddedVerifierByteArray).toString('base64');

  const encryptedSecretKeyWithPasswordResult: IEncryptedSecretKeyWithPasswordResult = {
    AccountKey: accountKeyBase64,
    NewPasswordSalt: newSaltBase64,
    NewPasswordVerifier: newVerifierBase64,
  };
  return encryptedSecretKeyWithPasswordResult;
}

export async function encryptSecretKeyForPasswordReset(
  accountId: string,
  password: string,
  accountSecretKey: string,
  recoveryCode?: string,
) {
  if (recoveryCode) {
    return await reEncryptShelteredKeyWithPassword(recoveryCode, accountId, password, accountSecretKey);
  } else {
    const iterations = 2048;
    const keySize = 16;
    const headerSize = 8;
    const accountKeyByteArray = new Uint8Array(Buffer.from(accountSecretKey, 'base64'));

    return await encryptSecretKeyWithPassword(
      accountId,
      password,
      accountKeyByteArray,
      iterations,
      keySize,
      headerSize,
    );
  }
}
