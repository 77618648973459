import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { api } from 'api';
import { ClientOs, ClientType, ServiceConfigStatus } from 'models';

export type ClientOSType = keyof typeof ClientOs;

export const loadInitialConfigAction = createAsyncThunk(
  'serviceConfig/setInitialConfig',
  async (payload: { isNativeClient: boolean }, { rejectWithValue }) => {
    try {
      // Flag indicates that LoginService is loaded/embedded inside the client window
      const isNativeClient = payload.isNativeClient || Cookies.get('isNativeClient') === '1';
      const clientOs = ClientOs[Cookies.get('ClientOS') as ClientOSType] ?? ClientOs.Unknown;

      const response = await api.post('/config/getinitialconfig');
      if (!response?.data?.s) {
        return rejectWithValue({
          serviceConfigStatus: ServiceConfigStatus.Undefined,
          error: {
            isError: true,
            message: 'Failed to fetch initial configs.',
          },
        });
      }

      return {
        serviceConfigStatus: ServiceConfigStatus.Set,
        config: {
          clientType: isNativeClient ? ClientType.NativeClient : ClientType.WebClient,
          clientOs,
          requireCaptcha: response.data.d.IsCaptchaRequired,
          isAlibabaCaptcha: response.data.d.IsAlibabaCaptcha,
          countryCode: response.data.d.CountryCode,
        },
      };
    } catch (err) {
      return rejectWithValue({
        serviceConfigStatus: ServiceConfigStatus.Dirty,
        error: {
          isError: true,
          message: 'Unexpected error occured. Please try again later.',
        },
      });
    }
  },
);
