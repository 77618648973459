import { useAppSelector } from 'hooks';
import { ClientType } from 'models';

// helpers
export function useMetadata() {
  const {
    config: { clientType, countryCode },
  } = useAppSelector((state) => state.serviceConfig);

  // Get i18n cookie
  const matchedI18nCookie = document.cookie.match(new RegExp('(^| )lang=([^;]+)'));
  const language = matchedI18nCookie ? matchedI18nCookie[2] : 'en';

  return {
    isNative: clientType === ClientType.NativeClient,
    language,
    userLocation: countryCode.toLowerCase(),
  };
}

export type AnalyticsMetadata = ReturnType<typeof useMetadata>;
