import { makeStyles } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      backIconStyles: {
        paddingRight: tokens.spacing(1.5),
        paddingBottom: tokens.spacing(2),
      },
      buttonStyles: {
        borderRadius: tokens.borderRadius.m,
        height: tokens.spacing(11),
        marginTop: tokens.spacings.l,
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(9),
        margin: 0,
      },
      subtitleStyles: {
        lineHeight: tokens.spacing(5),
        marginTop: `${tokens.spacings.s} !important`,
      },
      marginStyleBoxLeft: {
        border: `1px solid #EDEFF5`,
        flex: 1,
        borderRadius: tokens.spacings.s,
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          width: '100%',
          height: '100%',
        },
        marginRight: tokens.spacings.sm,
        paddingBottom: tokens.spacings.l,
      },
      marginStyleBoxRight: {
        border: `1px solid #EDEFF5`,
        flex: 1,
        borderRadius: tokens.spacings.s,
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          width: '100%',
          height: '100%',
        },
        paddingBottom: tokens.spacings.l,
      },
      animationStyle: {
        margin: tokens.spacings.xl,
        flex: 1,
        textAlign: 'center',
      },
    }) as const,
);
