import { useSearchParams } from 'react-router-dom';
import { Stack } from '@teamviewer/ui-library';

import LinkWithRedirect from 'components/LinkWithRedirect';
import { configManager } from 'config';

import { teamViewerImageLogoStyles, teamViewerLogoWrapperStyles } from './Header.styles';

const Header = () => {
  const [currentSearchParams] = useSearchParams();
  const redirectUri = currentSearchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const validRedirectUri =
    redirectUri === 'https://teamviewer-internal-loginservice' ||
    redirectUri === 'tvoneweblogin://centralizedclientlogin'
      ? configManager.get('defaultRedirectUri')
      : redirectUri;

  const logoUrl = new URL(window.location.origin);
  logoUrl.search = logoUrl.searchParams
    .merge(
      new URLSearchParams({
        redirect_uri: validRedirectUri,
      }),
    )
    .toString();

  return (
    <Stack
      horizontal
      verticalAlign="center"
      styles={teamViewerLogoWrapperStyles}
      as="header"
      data-testid="header-container"
    >
      <Stack.Item styles={teamViewerImageLogoStyles}>
        <LinkWithRedirect href={logoUrl.href}>
          <img src="/assets/tv-logo.svg" alt="TeamViewer Logo" data-testid="header-logo" />
        </LinkWithRedirect>
      </Stack.Item>
    </Stack>
  );
};

export default Header;
